import React, { lazy, Suspense } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const SelectOrderType = lazy(() => import("./SelectOrderType"));
const ItemList = lazy(() => import("./ItemList"));
const SelectLocation = lazy(() => import("./SelectLocation"));
const AddEditItem = lazy(() => import("./AddEditItem"));
const OrderPlaced = lazy(() => import("./OrderPlaced"));
const CartBase = lazy(() => import("./Components/CartBase"));
const PaymentDetails = lazy(() => import("./PaymentDetails"));
const AppBar = lazy(() => import("./Components/SidenavAppbar"));
const OrderReceipt = lazy(() => import("./OrderReceipt"));
const CartDetails = lazy(() => import("./CartDetails"));
const InactivePlan = lazy(() => import("./InactivePlan"));
const SelectVendor = lazy(() => import("./SelectVendor"));
const NoMenu = lazy(()=> import ("./NoMenu"));
const PropertyClosed = lazy(()=> import ("./PropertyClosed"))
const renderLoader = () => (
  <Backdrop sx={{ zIndex: 9999 }} open={true}>
    <CircularProgress color="primary" />
  </Backdrop>
);

const ApplicationRoutes = [
  {
    path: "/menu",
    element: (
      <Suspense fallback={renderLoader()}>
        <AppBar />
      </Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={renderLoader()}>
            <ItemList />
          </Suspense>
        ),
      },
      {
        path: "addItem",
        element: (
          <Suspense fallback={renderLoader()}>
            <AddEditItem />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/cart",
    element: (
      <Suspense fallback={renderLoader()}>
        <CartBase />
      </Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={renderLoader()}>
            <CartDetails />
          </Suspense>
        ),
      },
      {
        path: "updateItem",
        element: (
          <Suspense fallback={renderLoader()}>
            <AddEditItem />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/payment/:orderId",
    element: (
      <Suspense fallback={renderLoader()}>
        <PaymentDetails />
      </Suspense>
    ),
  },
  {
    path: "/location",
    element: (
      <Suspense fallback={renderLoader()}>
        <SelectLocation />
      </Suspense>
    ),
  },
  {
    path: "/property/:property_id",
    element: (
      <Suspense fallback={renderLoader()}>
        <SelectOrderType />
      </Suspense>
    ),
  },
  {
    path: "/property/:property_id/ordertype/:order_type/",
    element: (
      <Suspense fallback={renderLoader()}>
        <SelectOrderType />
      </Suspense>
    ),
  },
  {
    path: "/property/:property_id/ordersource/:order_type/",
    element: (
      <Suspense fallback={renderLoader()}>
        <SelectOrderType />
      </Suspense>
    ),
  },


  {
    path: "/vendors/:property_id/",
    element: (
      <Suspense fallback={renderLoader()}>
        <SelectVendor />
      </Suspense>
    ),
  },
  {
    path: "/order_placed/:orderId/",
    element: (
      <Suspense fallback={renderLoader()}>
        <OrderPlaced />
      </Suspense>
    ),
  },
  {
    path: "/receipt/:orderId/",
    element: (
      <Suspense fallback={renderLoader()}>
        <OrderReceipt />
      </Suspense>
    ),
  },
  {
    path: "/inactive-plan/",
    element: (
      <Suspense fallback={renderLoader()}>
        <InactivePlan />
      </Suspense>
    ),
  },
  {
    path: "/no-menu/",
    element: (
      <Suspense fallback={renderLoader()}>
        <NoMenu />
      </Suspense>
    ),
  },
  {
    path: "/property-closed/",
    element: (
      <Suspense fallback={renderLoader()}>
        <PropertyClosed />
      </Suspense>
    ),
  },
];

export default ApplicationRoutes;
