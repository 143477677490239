import { useQuery, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import axios from "src/axios";
import useSession from "src/Hooks/useSession";
import { useSnackbar } from "notistack";
import qs from "qs";

export function useAllRestaurantsAction(params) {
  const { enqueueSnackbar } = useSnackbar();
  const fetch_near_by_location = async () =>
    await axios.get("/web/geolocation/fetch-near-by-locations", {
      params: params,
    });

  let enabled = params && params.longitude && params.latitude ? true : false;

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["restaurants_list", params],
    enabled: enabled,
    queryFn: fetch_near_by_location,
    onError: (e) => {
      enqueueSnackbar("Something went wrong, Failed to fetch restaurants", {
        variant: "error",
      });
    },
  });

  return { data, isLoading: enabled && isLoading, error, isError, refetch };
}

export function useFetchPropertyByPropertyIdAction(params) {
  const fetch_property_by_property_id = async () =>
    await axios.get("/web/property/fetch-property", {
      params: params,
    });
  let enabled = params && params.enabled && params.property_id ? true : false;

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["property_by_property_id", params],
    enabled: enabled,
    queryFn: fetch_property_by_property_id,
  });
  return { data, isLoading: enabled && isLoading, error, isError, refetch };
}

export function useFetchVendorsAction(params) {
  const fetch_vendors = async () =>
    await axios.get("/web/property/fetch-vendors", {
      params: params,
    });
  let enabled = params && params.enabled && params.property_id ? true : false;

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["vendors_by_property_id", params],
    enabled: enabled,
    queryFn: fetch_vendors,
  });
  return { data, isLoading: enabled && isLoading, error, isError, refetch };
}

export function useFetchPlanStatusesAction(params) {
  const fetch_plan_statuses = async () =>
    await axios.get("/web/fetch-plan-statuses/", {
      params: params,
    });
  let enabled = params && params.property_id ? true : false;

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["plan_statuses", params],
    enabled: enabled,
    queryFn: fetch_plan_statuses,
  });
  return { data, isLoading: enabled && isLoading, error, isError, refetch };
}

export function useAllMenuAction(params) {
  const fetch_all_menu = async () =>
    await axios.get("/web/fetch-all-menu", {
      params: params,
    });
  let enabled = params && params.property_id ? true : false;

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["menu_list", params],
    enabled: enabled,
    queryFn: fetch_all_menu,
  });
  return { data, isLoading: enabled && isLoading, error, isError, refetch };
}

export function useAllCategoriesAndItemsAction(params) {
  const fetch_all_categories_and_items = async () =>
    await axios.get("/web/fetch-all-categories-and-items", {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
  let enabled = params && params.menu_id ? true : false;
  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["categories_and_items_list", params],
    enabled: enabled,
    queryFn: fetch_all_categories_and_items,
  });
  return { data, isLoading: enabled && isLoading, error, isError, refetch };
}

export function useAllFiltersAction(params) {
  const fetch_all_filters_items = async () =>
    await axios.get("web/web-service/fetch-all-filters", {
      params: params,
    });
  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["filters_list"],
    queryFn: fetch_all_filters_items,
  });
  return { data, isLoading, error, isError, refetch };
}

export function useFetchItemByItemIdAction(params) {
  const fetch_item_by_item_id = async () =>
    await axios.get("/web/fetch-item-by-item-id", {
      params: params,
    });
  let enabled = params && params.item_id ? true : false;

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["item_by_item_id", params],
    enabled: enabled,
    queryFn: fetch_item_by_item_id,
  });
  return { data, isLoading: enabled && isLoading, error, isError, refetch };
}

export function useFetchComboGroupsAndItemsAction(params) {
  const fetch_combo_groups_and_items = async () =>
    await axios.get("/web/fetch-combo-groups-and-items", {
      params: params,
    });
  let enabled = params && params.item_id && params?.is_combo ? true : false;

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["combo_groups_and_items", params],
    enabled: enabled,
    queryFn: fetch_combo_groups_and_items,
  });
  return { data, isLoading: enabled && isLoading, error, isError, refetch };
}

export function useAllAddonsAction({ is_addon_available = true, ...params }) {
  const fetch_all_addons = async () =>
    await axios.get("/web/fetch-all-addons", {
      params: params,
    });
  let enabled =
    params && params.item_id && !params?.is_combo && is_addon_available
      ? true
      : false;

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["addons_list", params],
    enabled: enabled,
    queryFn: fetch_all_addons,
  });
  return { data, isLoading: enabled && isLoading, error, isError, refetch };
}

export function isDeliveryInRange(params) {
  return axios.get("/web/geolocation/is-delivery-in-range", {
    params: params,
  });
}

export function useCartDetailsAction(params) {
  const { dispatch } = useSession();
  const fetch_cart = async () =>
    await axios.get("/web/cart/fetch-cart-by-cart-id", {
      params: params,
    });
  let enabled = params && params.cart_id?.length ? true : false;
  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["cart_details", params],
    enabled: enabled,
    queryFn: fetch_cart,
    // refetchOnMount: "always",
    onSuccess: (data, variables, context) => {
      if (!data?.data?.status) {
        dispatch({
          type: "REMOVE_CART_ID",
        });
        dispatch({
          type: "REMOVE_NOTES",
        });
      }
    },
  });
  return { data, isLoading: enabled && isLoading, error, isError, refetch };
}

export function useOrderDetailsAction(params) {
  const fetch_order = async () =>
    await axios.get("/web/order/fetch-order-details-by-order-id", {
      params: params,
    });
  let enabled = params && params.order_id ? true : false;
  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["order_details", params],
    enabled: enabled,
    queryFn: fetch_order,
  });
  return { data, isLoading: enabled && isLoading, error, isError, refetch };
}

export function useAddItemsToCartAction(successCallback) {
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch } = useSession();
  const mutation = useMutation(
    async (data) => {
      return await axios.post("/web/cart/add-cart-items", data);
    },
    {
      onSuccess: (data, variables, context) => {
        if (data?.data?.status) {
          dispatch({
            type: "SET_CART_ID",
            payload: {
              cart_id: data?.data?.data?.cart_id,
            },
          });
          enqueueSnackbar("Added item to cart succesfully", {
            variant: "success",
          });
          successCallback && successCallback();
        } else {
          enqueueSnackbar(data?.data?.message || "Something went wrong", {
            variant: "error",
          });
        }
      },
    }
  );

  return { mutation: mutation };
}

export function useUpdateCartItemAction(successCallback) {
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    async (data) => {
      return await axios.post("/web/cart/update-cart-addon-quantity", data);
    },
    {
      onSuccess: (data, variables, context) => {
        if (data?.data?.status) {
          enqueueSnackbar("Updated cart item succesfully", {
            variant: "success",
          });
          successCallback && successCallback();
        } else {
          enqueueSnackbar(data?.data?.message || "Something went wrong", {
            variant: "error",
          });
        }
      },
      onError: (e) => {
        enqueueSnackbar(e || "Something went wrong", { variant: "error" });
      },
    }
  );

  return { mutation: mutation };
}

export function useUpdateCartItemAndAddonQuantityAction(successCallback) {
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    async (data) => {
      return await axios.post(
        "/web/cart/update-cart-item-and-addon-quantity",
        data
      );
    },
    {
      onSuccess: (data, variables, context) => {
        if (data?.data?.status) {
          enqueueSnackbar("Updated cart item succesfully", {
            variant: "success",
          });
          successCallback && successCallback();
        } else {
          enqueueSnackbar(data?.data?.message || "Something went wrong", {
            variant: "error",
          });
        }
      },
    }
  );

  return { mutation: mutation };
}

export function getPaymentMethods(params) {
  return axios.get("/web/adyen/payment-methods", {
    params: params,
  });
}

export function getPaymentDetails(params) {
  return axios.get("/web/adyen/payment-details", {
    params: params,
  });
}

export function usePlaceOrderAction() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { dispatch } = useSession();
  const mutation = useMutation(
    async (data) => {
      return await axios.post("/web/order/place-order", data);
    },
    {
      onSuccess: (data, variables, context) => {
        if (data?.data?.status && data?.data?.data?.order_id) {
          navigate(`/payment/${data?.data?.data?.order_id}`);
          dispatch({
            type: "REMOVE_CART_ID",
          });
          dispatch({
            type: "REMOVE_NOTES",
          });
        } else {
          const errorMessage = data?.data?.message;
          if (errorMessage) {
            const messageObject = JSON.parse(errorMessage);
            const errorCode = messageObject?.error?.message;
            enqueueSnackbar(errorCode || "Something went wrong", {
              variant: "error",
            });
          }
        }
      },
    }
  );

  return { mutation: mutation };
}

export function useInitiatePaymentAction() {
  const mutation = useMutation(
    async (data) => {
      return await axios.post("/web/adyen/initiate-payment", data?.params);
    },
    {
      onSuccess: (data, variables, context) => {
        variables?.successCallback &&
          variables?.successCallback(data, variables, context);
      },
    }
  );

  return { mutation: mutation };
}

export function useCancelPaymentAction(successCallback) {
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    async (data) => {
      return await axios.post(
        `/web/adyen/cancel-payment?order_id=${data?.order_id}`
      );
    },
    {
      onSuccess: (data, variables, context) => {
        if (data?.data?.status) {
        } else {
          enqueueSnackbar(data?.data?.message || "Something went wrong", {
            variant: "error",
          });
        }
      },
    }
  );

  return { mutation: mutation };
}

export function useApplyDiscountAction(successCallback) {
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    async (data) => {
      return await axios.post(
        `/web/order/apply-discount?order_id=${
          data?.order_id
        }&discount_code=${encodeURIComponent(data?.discount_code)}`
      );
    },
    {
      onSuccess: (data, variables, context) => {
        if (data?.data?.status) {
          enqueueSnackbar("Discount Applied", {
            variant: "success",
          });
          successCallback();
        } else {
          enqueueSnackbar(data?.data?.message || "Something went wrong", {
            variant: "error",
          });
        }
      },
    }
  );

  return { mutation: mutation };
}
export function useRemoveDiscountAction(successCallback) {
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    async (data) => {
      return await axios.post(
        `/web/order/cancel-discount?order_id=${data?.order_id}`
      );
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status) {
          enqueueSnackbar("Discount Removed", {
            variant: "success",
          });
          successCallback();
        } else {
          enqueueSnackbar(data?.data?.message || "Something went wrong", {
            variant: "error",
          });
        }
      },
    }
  );

  return { mutation: mutation };
}
export function useAmmendOrderAction(successCallback) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const mutation = useMutation(
    async (data) => {
      return await axios.post(`/web/order/amend-order`, null, { params: data });
    },
    {
      onSuccess: (data, variables, context) => {
        if (data?.data?.status) {
          successCallback && successCallback(data?.data);
        } else {
          enqueueSnackbar(data?.data?.message || "Something went wrong", {
            variant: "error",
          });
          navigate("/menu");
        }
      },
    }
  );

  return { mutation: mutation };
}

export function useRatingsAction(successCallback) {
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    async (data) => {
      return await axios.post(`/web/order/ratings`, null, {
        params: data,
      });
    },
    {
      onSuccess: (data, variables, context) => {
        if (data?.data?.status) {
          enqueueSnackbar("Feedback Submitted Succesfully", {
            variant: "success",
          });
          successCallback && successCallback(data?.data);
        } else {
          enqueueSnackbar(data?.data?.message || "Something went wrong", {
            variant: "error",
          });
        }
      },
    }
  );

  return { mutation: mutation };
}
export function usePayLaterAction(successCallback) {
  const mutation = useMutation(
    async (data) => {
      return await axios.post("/web/order/pay-later", null, { params: data });
    },
    {
      onSuccess: (data, variables, context) => {
        successCallback && successCallback();
      },
    }
  );

  return { mutation: mutation };
}

export function useCancelJobAction(successCallback) {
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    async (data) => {
      return await axios.post(`/web/nash/cancel-job`, null, {
        params: data,
      });
    },
    {
      onSuccess: (data, variables, context) => {
        if (data?.data?.status) {
          enqueueSnackbar(data?.data?.message || " ", {
            variant: "success",
          });
          successCallback && successCallback(data?.data);
        } else {
          enqueueSnackbar(data?.data?.message || "Something went wrong", {
            variant: "error",
          });
        }
      },
    }
  );

  return { mutation };
}
