import React, { useEffect, createContext, useReducer } from "react";
import MenuSkelton from "src/Components/Skelton/MenuSkelton";

const initialState = {
  isInitialised: false,
  sessionDetails: {},
  cart_id: null,
  cartAddedAddons: [],
  notes: "",
  table_number: "",
  is_room_order: false,
  home_url: "",
  delivery_address: {},
  show_delivery_dialog: null,
  show_filter_dialog: false,
  delivery_form_values: {},
  selected_filters: {},
  is_multi_vendor:false,
  multi_vendor_property:"",
};

const setSession = (
  data,
  tableNo = "",
  isRoomOrder = false,
  home_url,
  notes
) => {
  window.sessionStorage.setItem("kayana_session_details", JSON.stringify(data));
  window.sessionStorage.setItem("table_number", tableNo);
  window.sessionStorage.setItem("is_room_order", isRoomOrder);
  window.sessionStorage.setItem("home_url", home_url);
  window.sessionStorage.setItem("notes", notes);
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT":
      return {
        ...state,
        isInitialised: true,
      };
    case "SET_SESSION": {
      const { table_number, is_room_order, home_url, ...sessionDetails } =
        action.payload;
      setSession(
        sessionDetails,
        table_number || "",
        is_room_order || false,
        home_url || state?.home_url
      );
      return {
        ...state,
        sessionDetails: sessionDetails,
        table_number: table_number || "",
        is_room_order: !!is_room_order,
        home_url: home_url || state?.home_url,
        notes: "",
      };
    }
    case "SET_MULTI_VENDOR_PROPERTY": {
      const { is_multi_vendor, multi_vendor_property } = action.payload;
    
      const MultiVendorSessionData = {
        is_multi_vendor: is_multi_vendor,
        multi_vendor_property: multi_vendor_property
      };
    
      window.sessionStorage.setItem("MultiVendorSessionData", JSON.stringify(MultiVendorSessionData));
    
      return {
        ...state,
        is_multi_vendor,
        multi_vendor_property
      };
    }

    case "SET_DELIVERY_ADDRESS": {
      const { delivery_address } = action.payload;
      window.sessionStorage.setItem(
        "delivery_address",
        JSON.stringify(delivery_address)
      );
      return {
        ...state,
        delivery_address: delivery_address,
      };
    }

    case "REMOVE_DELIVERY_ADDRESS": {
      window.sessionStorage.removeItem("delivery_address");
      return {
        ...state,
        delivery_address: {},
      };
    }

    case "SHOW_DELIVERY_DIALOG": {
      return {
        ...state,
        show_delivery_dialog: {
          open: true,
          onLocationSelect: action?.payload?.onLocationSelect,
        },
      };
    }

    case "CLOSE_DELIVERY_DIALOG": {
      return {
        ...state,
        show_delivery_dialog: null,
      };
    }

    case "SHOW_FILTER_DIALOG": {
      return {
        ...state,
        show_filter_dialog: true,
      };
    }

    case "CLOSE_FILTER_DIALOG": {
      return {
        ...state,
        show_filter_dialog: false,
      };
    }

    case "SET_SELECTED_FILTERS": {
      const { selected_filters } = action.payload;
      window.sessionStorage.setItem(
        "selected_filters",
        JSON.stringify(selected_filters)
      );
      return {
        ...state,
        selected_filters: selected_filters,
      };
    }

    case "SET_DELIVERY_FORM_VALUES": {
      const { delivery_form_values } = action.payload;
      window.sessionStorage.setItem(
        "delivery_form_values",
        JSON.stringify(delivery_form_values)
      );
      return {
        ...state,
        delivery_form_values: delivery_form_values,
      };
    }

    case "REMOVE_DELIVERY_FORM_VALUES": {
      window.sessionStorage.removeItem("delivery_form_values");
      return {
        ...state,
        delivery_form_values: {},
      };
    }

    case "SET_NOTES": {
      const { notes } = action.payload;
      window.sessionStorage.setItem("notes", notes);
      return {
        ...state,
        notes: notes,
      };
    }

    case "REMOVE_NOTES": {
      window.sessionStorage.removeItem("notes");
      return {
        ...state,
        notes: "",
      };
    }

    case "SET_TABLE_NUMBER": {
      const { table_number } = action.payload;
      window.sessionStorage.setItem("table_number", table_number);
      return {
        ...state,
        table_number: table_number,
      };
    }

    case "SET_CART_ID": {
      const { cart_id } = action.payload;
      window.sessionStorage.setItem("kayana_cart_id", cart_id);
      return {
        ...state,
        cart_id: cart_id,
      };
    }

    case "REMOVE_CART_ID": {
      window.sessionStorage.removeItem("kayana_cart_id");
      return {
        ...state,
        cart_id: null,
      };
    }

    case "ADD_CART_ADDED_ADDONS": {
      const { cart_added_addons } = action.payload;
      window.sessionStorage.setItem(
        "kayana_cart_added_addons",
        JSON.stringify([...state.cartAddedAddons, ...cart_added_addons])
      );
      return {
        ...state,
        cartAddedAddons: [...state.cartAddedAddons, ...cart_added_addons],
      };
    }
    case "REMOVE_CART_ADDED_ADDONS": {
      window.sessionStorage.removeItem("kayana_cart_added_addons");
      return {
        ...state,
        cartAddedAddons: [],
      };
    }
    default: {
      return { ...state };
    }
  }
};

const SessionContext = createContext({
  ...initialState,
});

export const SessionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    try {
      const ses_det = window.sessionStorage.getItem("kayana_session_details");
      const cart_id = window.sessionStorage.getItem("kayana_cart_id");
      const table_number = window.sessionStorage.getItem("table_number");
      const is_room_order = window.sessionStorage.getItem("is_room_order");
      const home_url = window.sessionStorage.getItem("home_url");
      const cart_added_addons = window.sessionStorage.getItem(
        "kayana_cart_added_addons"
      );
      const delivery_address =
        window.sessionStorage.getItem("delivery_address");
      const delivery_form_values = window.sessionStorage.getItem(
        "delivery_form_values"
      );
      const selected_filters =
        window.sessionStorage.getItem("selected_filters");
      if (ses_det) {
        dispatch({
          type: "SET_SESSION",
          payload: {
            ...JSON.parse(ses_det),
            table_number: table_number || "",
            is_room_order: is_room_order === "true" ? true : false,
            home_url: home_url || "",
          },
        });
      }
      if (delivery_address) {
        dispatch({
          type: "SET_DELIVERY_ADDRESS",
          payload: {
            delivery_address: JSON.parse(delivery_address),
          },
        });
      }
      if (delivery_form_values) {
        dispatch({
          type: "SET_DELIVERY_FORM_VALUES",
          payload: {
            delivery_form_values: JSON.parse(delivery_form_values),
          },
        });
      }
      if (selected_filters) {
        dispatch({
          type: "SET_SELECTED_FILTERS",
          payload: {
            selected_filters: JSON.parse(selected_filters),
          },
        });
      }
      if (cart_id) {
        dispatch({
          type: "SET_CART_ID",
          payload: {
            cart_id: cart_id,
          },
        });
        if (cart_added_addons) {
          dispatch({
            type: "ADD_CART_ADDED_ADDONS",
            payload: {
              cart_added_addons: JSON.parse(cart_added_addons),
            },
          });
        }
      }
      dispatch({ type: "INIT" });
    } catch (err) {}
  }, []);

  return state?.isInitialised ? (
    <SessionContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </SessionContext.Provider>
  ) : (
    <MenuSkelton />
  );
};

export default SessionContext;
